@value breakpointSmUp, breakpointMdUp from "@depop/web-ui-kit/theme/default/breakpoints.module.css";
@value md from "@depop/web-ui-kit/theme/default/spacing.module.css";

@layer components {
  .container {
    display: flex;
    align-items: center;
    margin: 0 0 md;
    justify-content: space-between;
  }

  .userDetails {
    display: flex;
    gap: md;
    align-items: center;
  }
}
